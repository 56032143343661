import { text } from "@fortawesome/fontawesome-svg-core";

const clientFormJson = {
  title: "Katina Client Form",
  logoPosition: "right",
  showProgressBar: "both",
  progressBarType: "questions",
  completedHtml:
    "<h4>Thank you for completing the form. You will receive communication from us very soon about available appointments. Keep an eye out for any email communication from Katina Online. Please check your spam folder.</h4>",
  pages: [
    {
      name: "page1",
      elements: [
        {
          type: "text",
          name: "firstName",
          maxWidth: "50%",
          startWithNewLine: false,
          title: "First Name",
          isRequired: true,
          autocomplete: "name",
        },
        {
          type: "text",
          name: "lastName",
          maxWidth: "50%",
          startWithNewLine: false,
          title: "Last Name",
          isRequired: true,
          autocomplete: "family-name",
        },
        {
          type: "text",
          name: "preferredName",
          title: "Preferred Name",
        },
        {
          "type": "html",
          "name": "question3",
          "html": "Please add the name and date of birth of the person requiring the service. If the person is a minor, there will be an option to add parent/guardian details"
        },
        {
          type: "text",
          name: "email",
          title: "Email",
          isRequired: true,
          validators: [
            {
              type: "email",
              text: "Please enter a valid email",
            },
          ],
          inputType: "email",
          autocomplete: "email",
        },
        {
          type: "text",
          name: "dob",
          startWithNewLine: false,
          title: "Date of Birth",
          isRequired: true,
          inputType: "date",
        },
        {
          type: "expression",
          name: "Age",
          startWithNewLine: false,
          title: "Age",
          expression: "age({dob})",
          displayStyle: "decimal",
          maximumFractionDigits: 0,
        },
        {
          type: "checkbox",
          name: "pronouns",
          title: "Pronouns",
          isRequired: true,
          choices: ["He/Him", "She/Her", "Them/They"],
          showOtherItem: true,
          otherText: "Other (please specify)",
        },
        {
          type: "text",
          name: "phone",
          startWithNewLine: false,
          title: "Phone",
          isRequired: true,
          inputType: "tel",
        },
        {
          type: "boolean",
          name: "leaveAMessage",
          title: "Can we leave a message on your phone?",
          isRequired: true,
        },
        {
          type: "comment",
          name: "Guardian's name (?)",
          visibleIf: "{Age} < 18",
          requiredIf: "{Age} < 18",
          title: "Please provide name and contact details of both parents or guardians",
        },
        {
          type: "boolean",
          name: "currentClient",
          title: "Are you a current or returning client of the clinic?",
          isRequired: true,
        },
        {
          type: "text",
          visibleIf: "{currentClient} = true",
          name: "existingPractitioner",
          title: "Which practitioner did you/are you seeing?",
          isRequired: true,
        },
        {
          type: "boolean",
          name: "referralOfFriendOrFamily",
          title: "Do you have a friend or family member that attends or has attended this practice?",
          isRequired: true,
        }
      ],
    },
    {
      name: "page2",
      elements: [
        {
          type: "radiogroup",
          name: "service",
          title: "Service",
          isRequired: true,
          choices: [
            {
              value: "Assessments",
              text: "Psychological Assessments",
            },
            {
              value: "Psychology",
              text: "Psychological Therapy/Counselling",
            },
            { value: "Hypnotherapy", text: "Hypnotherapy" },
            { value: "Occupational Therapy", text: "Occupational Therapy" },
            {
              value: "Occupational Therapy Assessment",
              text: "Occupational Therapy Assessment",
            },
            { value: "Speech Therapy", text: "Speech Therapy" },
            { value: "Group Therapy", text: "Group Therapy" },
            { value: "Chiropractic", text: "Chiropractic" },
            { value: "Osteopathy", text: "Osteopathy" },
            { value: "Physiotherapy", text: "Physiotherapy" },
            { value: "Support Coordination", text: "Support Coordination" },
            { value: "Recovery Coaching", text: "Recovery Coaching" },
            {
              value: "School Leaver Employment Services (SLES)",
              text: "School Leaver Employment Services (SLES)",
            },
            { value: "Dietetics", text: "Dietetics" },
            { value: "Nutrition", text: "Nutrition" },
            { value: "Exercise Physiology", text: "Exercise Physiology" },
            { value: "Psychiatry", text: "Psychiatry" },
            {
              value: "Home Medication Review", text: "Home Medication Review",
            },
            {
              value: "Diabetes Educator", text: "Diabetes Educator",
            },
            {
              value: "Lactation Consultant", text: "Lactation Consultant",
            },
            {
              value: "Functional Medicine Practitioner", text: "Functional Medicine Practitioner"
            }
          ],
          otherText: "Other (please specify)",
        },
        {
          type: "radiogroup",
          name: "groupTherapySubCategories",
          visibleIf: "{service} = 'Group Therapy'",
          title: "Please select the type of group therapy you are wanting to participate in?",
          choices: [
            "Handwriting Group",
            "Life Skills Group",
            "Mother's Support Course",
            "Support to Manage Behaviour, Everyday Tasks & Meltdowns",
            "Adventure Club",
            "Little Steps Playgroup & Mother's Support Group",
            "Art Therapy",
            "Social Skills",
            "Parenting Neurodivergent Children",
            "Parenting"
          ]
        },
        {
          title:
            "Please select the type of occupational therapy you are seeking?",
          type: "checkbox",
          name: "occupationalTherapySubCategories",
          visibleIf: "{service} = 'Occupational Therapy'",
          requiredIf: "{service} = 'Occupational Therapy'",
          otherText: "Other (please specify)",
          showOtherItem: true,
          choices: [
            "Sensory Processing",
            "Attention/Concentration/Self-regulation",
            "Toileting",
            "Self-care skills (e.g. hygiene, dressing)",
            "Fussy Eating",
            "Handwriting",
            "Learning challenges",
            "Acute Fractures",
            "Paediatric Injuries",
            "Surgical Tendons",
            "Post-op tendon repair",
            "Geographical barriers (People outside Townsville/remote areas)",
            "Nerve concerns",
            "Dislocation",
            "Ligament injuries",
            "Soft tissue injuries",
            "Repetitive conditions",
            "Arthritis",
          ],
        },
        {
          title:
            "Please select the type of occupational therapy assessment you are seeking?",
          type: "checkbox",
          name: "occupationalTherapyAssessmentSubCategories",
          visibleIf: "{service} = 'Occupational Therapy Assessment'",
          requiredIf: "{service} = 'Occupational Therapy Assessment'",
          choices: [
            "Developmental assessment",
            "Functional Capacity assessment",
            "Motor Skills assessment",
            "Learning assessment",
          ],
        },
        {
          type: "boolean",
          name: "hadAnySurgeries",
          title: "Have you had any surgeries?",
          visibleIf:
            "{service} = 'Occupational Therapy' or {service} = 'Occupational Therapy Assessment'",
          isRequired: true,
        },
        {
          name: "whereSurgery",
          type: "checkbox",
          title: "Where were the surgeries conducted?",
          visibleIf: "{hadAnySurgeries} = true",
          otherText: "Other (please specify)",
          showOtherItem: true,
          choices: [
            "Upper Limb (incl. spine down to fingertips)",
            "Lower Limb"
          ]
        },
        {
          type: "comment",
          name: "whatSurgery",
          visibleIf: "{hadAnySurgeries} = true",
          title: "What surgeries were performed?",
        },
        {
          type: "comment",
          name: "whenSurgery",
          visibleIf: "{hadAnySurgeries} = true",
          title: "Approximate month and year the surgeries were performed?",
        },
        {
          type: "text",
          name: "doctorWhoPerformedSurgery",
          visibleIf: "{hadAnySurgeries} = true",
          title: "Who were the doctors performing the surgery?",
        },
        {
          type: "comment",
          name: "contactDetailsOfSurgeon",
          visibleIf: "{hadAnySurgeries} = true",
          title: "Contact details for clinic/hospital or surgeon, if possible"
        },
        {
          type: "radiogroup",
          name: "whoPays",
          visibleIf: "{service} <> 'Assessments'",
          title: "How will your sessions be funded?",
          description:
            "Please note, with some funding bodies, upfront payment by the client may be required and gap fees may also apply. We will discuss your out of pocket costs and payment processes when we contact you for an appointment.",
          isRequired: true,
          requiredIf: "{service} = 'Psychology'",
          choices: [
            "Self funded",
            "Self (with Medicare Rebate)",
            "Self (with private insurance rebate)",
            "Department of Veterans Affairs (DVA)",
            "National Disability Insurance Scheme (NDIS)",
            "WorkSafe",
            "Workcover",
            "Transport Accident Commission (TAC)",
            "Open Arms",
            "Victims of Crime",
            "Women’s Resource Information and Support Centre (WRISC)",
            "Employer Funded (Employee Assistance Program)",
            "Chronic Disease Management Plan",
            "Enhanced Primary Care Plan",
            "Team Care Arrangement"
          ],
          showOtherItem: true,
          otherText: "Other (please specify)",
        },
        {
          type: "text",
          name: "gpName",
          visibleIf: "{whoPays} = 'Self (with Medicare Rebate)'",
          title: "GP Name",
        },
        {
          type: "text",
          name: "gpClinic",
          visibleIf: "{whoPays} = 'Self (with Medicare Rebate)'",
          title: "GP Clinic",
        },
        {
          type: "text",
          name: "gpEmail",
          visibleIf: "{whoPays} = 'Self (with Medicare Rebate)'",
          title: "GP Clinic Email",
        },
        {
          type: "text",
          name: "dateOfAccident",
          visibleIf:
            "{whoPays} = 'Transport Accident Commission (TAC)' or {whoPays} = 'WorkSafe' or {whoPays} = 'Workcover'",
          title: "Date of Accident",
          inputType: "date",
        },
        {
          type: "text",
          name: "TACClaimNumber",
          visibleIf:
            "{whoPays} = 'Transport Accident Commission (TAC)' or {whoPays} = 'WorkSafe' or {whoPays} = 'Workcover'",
          title: "Claim Number",
        },
        {
          type: "radiogroup",
          name: "ndisManagedSelfManagedOrPlanManaged",
          visibleIf: "{whoPays} = 'National Disability Insurance Scheme (NDIS)'",
          title: "Are you NDIS Plan Managed or Self Managed?",
          choices: ["Plan Managed", "Self Managed"]
        },
        {
          type: "comment",
          name: "supportCoord",
          visibleIf:
            "{whoPays} = 'National Disability Insurance Scheme (NDIS)'",
          title: "Support Coordinator Details (if known)",
        },
        {
          type: "comment",
          name: "whoToInvoice",
          visibleIf:
            "{whoPays} = 'National Disability Insurance Scheme (NDIS)'",
          title: "Who to invoice? (if known)",
        },
        {
          type: "text",
          name: "sessionsBudget",
          visibleIf:
            "{whoPays} = 'National Disability Insurance Scheme (NDIS)'",
          title: "How many sessions/budget funded for? (if known)",
          inputType: "number",
        },
        {
          type: "text",
          name: "whiteCardNumber",
          visibleIf: "{whoPays} = 'Department of Veterans Affairs (DVA)'",
          title: "White Card or Gold Card Number",
        },
        {
          type: "boolean",
          name: "userUpSessions",
          visibleIf: "{whoPays} = 'Department of Veterans Affairs (DVA)'",
          title:
            "Have you used any sessions on your referral this calendar year?",
        },
        {
          type: "boolean",
          name: "existingSessions",
          visibleIf: "{whoPays} anyof ['Self (with Medicare Rebate)','Chronic Disease Management Plan']",
          title:
            "Have you used any sessions on your referral this calendar year?",
        },
        {
          type: "text",
          name: "howMany",
          visibleIf: "{existingSessions} = true",
          title: "If so, how many?",
          isRequired: true,
          inputType: "number",
          min: 0,
        },
        {
          type: "checkbox",
          name: "mainReason",
          visibleIf: "{service} = 'Psychology'",
          title:
            "What is the reason for your referral or the main concern you’re experiencing at this time? ",
          description:
            "Please select a maximum of three categories for the purpose of matching you to the best clinician. Rest assured that when you see the clinician you’ll be able to provide a lot more information. If you’re unsure of what you’re being referred for, please just click other and provide a description.",
          requiredIf: "{service} = 'Psychology'",
          choices: [
            "Mental Health",
            "Neurodivergence",
            "General Health",
            "Trauma/Harm",
            "Personal Problems and Life Stressors",
            "Gender",
            "Relationships",
            "Educational",
            "Suicidal Thinking",
            "Self Harming Behaviours",
            "Behavioural Problems",
            "Addictions",
            "Work/Community",
            "Couples/Family Therapy",
            "Legal",
          ],
          showOtherItem: true,
          colCount: 2,
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          choices: [
            {
              value: "Couples Therapy",
              text: "Couples Therapy (only select if you'd like therapy with a partner)",
            },
            {
              value: "Family Therapy",
              text: "Family Therapy (only select if you'd like group therapy)",
            },
            "Neurodiverse Couples Therapy / Coaching",
          ],
          name: "therapySubCategories",
          visibleIf:
            "{mainReason} contains ['Couples/Family Therapy'] and {service} = 'Psychology'",
          title: "Please select the types of therapy you are seeking?",
          requiredIf:
            "{mainReason} contains ['Couples/Family Therapy'] and {service} = 'Psychology'",
        },
        {
          type: "tagbox",
          name: "assessments",
          visibleIf: "{service} = 'Assessments'",
          title: "Which assessments would you like?",
          requiredIf: "{service} = 'Assessments'",
          choices: [
            "ASD Assessment",
            "ADHD Assessment",
            "Career and Vocational Assessment",
            "Compensation Assessment (workplace accident, medical negligence,victim of crime, motor vehicle)",
            "Educational Assessment",
            "Functional Assessment",
            "Immigration Assessment",
            "Impairment Assessment",
            "Intellectual/Cognitive Assessment",
            "Disability Support Pension (DSP) Assesment",
            "Neuropsychological Assessment",
            "Personality Assessment",
            "Autism Assessment",
            "Psychopathology Assessment",
            "Bariatric Pre-Surgery Assessment",
          ],
        },
        {
          type: "tagbox",
          name: "neurodivergenceSubCategories",
          visibleIf:
            "{mainReason} contains ['Neurodivergence'] and {service} = 'Psychology'",
          title: "Please select the type of Neurodivergence?",
          requiredIf:
            "{mainReason} contains ['Neurodivergence'] and {service} = 'Psychology'",
          choices: [
            "Attention Deficit Hyperactivity Disorder",
            "Autism Spectrum Disorder",
            "Autism",
          ],
          otherText: "Other (please specify)",
        },
        {
          type: "tagbox",
          name: "mentalHealthSubCategories",
          visibleIf:
            "{mainReason} contains ['Mental Health'] and {service} = 'Psychology'",
          title:
            "Please select the types of Mental Health issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Mental Health'] and {service} = 'Psychology'",
          choices: [
            "Adjustment Disorder",
            "Anxiety & Phobias",
            "BiPolar Disorder",
            "Body Dysmorphia",
            "Depression",
            "Dissociative Identity Disorder",
            "Eating Disorders",
            "Hoarding Disorder",
            "Obsessive Compulsive Disorder (OCD)",
            "Panic Disorder",
            "Personality Disorders",
            {
              label: "Peri/post Natal depression/anxiety",
              value: "Post-natal Depression/Anxiety",
            },
            "Psychosis",
            "Schizophrenia",
            "Selective Mutism",
            "Situational Mutism",
            "Tourette’s Syndrome (Tic Disorder)",
            "Trichotillomania (hair pulling)",
            "Excoriation (Skin picking)",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "generalHealthSubCategories",
          visibleIf:
            "{mainReason} contains ['General Health'] and {service} = 'Psychology'",
          title:
            "Please select the types of General Health issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['General Health'] and {service} = 'Psychology'",
          choices: [
            "Chronic Disease Management",
            "Genetic Counselling",
            "Health-related problems",
            "Anxiety about my health and physical symptoms",
            "Infertility Issues",
            "Memory Problems",
            "Pain Management",
            "Physical Disability",
            "Pre-cosmetic Surgery Assessment",
            "Perimenopause, menopause, postmenopause",
            "Rehab/Injury Counselling",
            "Sleeping problems",
            "Stress Management",
            "Weight Management",
            "Toileting problems (Encopresis; Enuresis)",
            "Chronic Illness",
            "General Health Issues",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "traumaSubCategories",
          visibleIf:
            "{mainReason} contains ['Trauma/Harm'] and {service} = 'Psychology'",
          title:
            "Please select the types of Trauma/Harm issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Trauma/Harm'] and {service} = 'Psychology'",
          choices: [
            "Adult Survivor of Sexual Abuse",
            "Birth trauma",
            "Complex Developmental Trauma",
            "Complex PTSD",
            "Bullying",
            "Child Abuse",
            "Disaster Recovery",
            "Family Violence",
            "Medical trauma",
            "Post Traumatic Stress Disorder (PTSD)",
            "Self-harm",
            "Sexual Abuse",
            "Suicide",
            "Victim of Crime",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "personalSubCategories",
          visibleIf:
            "{mainReason} contains ['Personal Problems and Life Stressors'] and {service} = 'Psychology'",
          title:
            "Please select the types of Personal Problems and Life Stressors issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Personal Problems and Life Stressors'] and {service} = 'Psychology'",
          choices: [
            "Anger Management",
            "Assertiveness Training",
            "Behaviour Problems",
            "Body Image",
            "Carer Support",
            "Grief and Loss",
            "Infertility Issues",
            "Life Transition and Adjustment Issues",
            "Emotion dysregulation",
            "Sport Performance",
            "Pregnancy Support",
            "Parenting Support",
            "Religious Issues",
            "Self Esteem & Self Development",
            "Sexual Difficulties",
            "Social Skills",
            "Stress management",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "genderSubCategories",
          visibleIf:
            "{mainReason} contains ['Gender'] and {service} = 'Psychology'",
          title:
            "Please select the types of Gender issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Gender'] and {service} = 'Psychology'",
          choices: [
            "Diverse Genders",
            "Diverse Sexualities",
            "Gender Dysphoria",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "relationshipSubCategories",
          visibleIf:
            "{mainReason} contains ['Relationships'] and {service} = 'Psychology'",
          title:
            "Please select the types of Relationship issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Relationships'] and {service} = 'Psychology'",
          choices: [
            "Conflict Resolution",
            "Couples Therapy",
            "Divorce/Separation",
            "Family Therapy",
            "Mediation",
            "Parenting",
            "Relationships",
            "Attachment disruption (fostering and adoption)",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "educationalSubCategories",
          visibleIf:
            "{mainReason} contains ['Educational'] and {service} = 'Psychology'",
          title:
            "Please select the types of Educational issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Educational'] and {service} = 'Psychology'",
          choices: [
            "Academic Performance",
            "Intellectual Disability",
            "Learning Difficulties",
            "School Refusal",
            "Problems with Peers",
            "Specific Learning Disorder",
            "Developmental Delays",
            "School Attendance Issues",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "addictionSubCategories",
          visibleIf:
            "{mainReason} contains ['Addictions'] and {service} = 'Psychology'",
          title:
            "Please select the types of Addiction issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Addictions'] and {service} = 'Psychology'",
          choices: [
            "Alcohol dependence",
            "Drug dependence",
            "Gambling",
            "Impulsive Behaviours",
            "Internet Gaming / Social Networking",
            "Pornography / Sex Addiction",
            "Smoking",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "workSubCategories",
          visibleIf:
            "{mainReason} contains ['Work/Community'] and {service} = 'Psychology'",
          title:
            "Please select the type of Work/Community issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Work/Community'] and {service} = 'Psychology'",
          choices: [
            "Employee Assistance Program",
            "Human Resource Management",
            "Leadership",
            "Organisational Restructuring",
            "Military, Police, & Emergency Work",
            "Performance Management",
            "Personnel Selection",
            "Work Stress",
            "Workplace Bullying",
            "Executive Coaching",
            "Workplace Accident",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "legalSubCategories",
          visibleIf:
            "{mainReason} contains ['Legal'] and {service} = 'Psychology'",
          title:
            "Please select the types of Legal issues you are experiencing?",
          requiredIf:
            "{mainReason} contains ['Legal'] and {service} = 'Psychology'",
          choices: [
            "Criminal Behaviour",
            "Expert Witness",
            "Family Court Issues",
            "Legal Report",
            "Management of Offenders",
            "Sex Offenders",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "subPersonalityDisorders",
          visibleIf:
            "{mentalHealthSubCategories} contains 'Personality Disorders' and {service} = 'Psychology'",
          title:
            "Please select the types of Personality Disorder issues you are experiencing?",
          requiredIf:
            "{mentalHealthSubCategories} contains 'Personality Disorders' and {service} = 'Psychology'",
          choices: [
            "Borderline Personality Disorder ",
            "Narcissistic Personality Disorder",
            "Antisocial Personality Disorder",
            "Paranoid Personality Disorder",
            "Dependent Personality Disorder ",
            "Obsessive Compulsive Personality Disorder",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "eatingSubCategories",
          visibleIf:
            "{mentalHealthSubCategories} contains 'Eating Disorders' and {service} = 'Psychology'",
          title:
            "Please select the types of Eating Disorder issues you are experiencing?",
          requiredIf:
            "{mentalHealthSubCategories} contains 'Eating Disorders' and {service} = 'Psychology'",
          choices: [
            "Anorexia Nervosa",
            "Bulimia Nervosa ",
            "Binge eating",
            "Pica (eating non-food substances)",
            "Avoidant/Restrictive Food Intake",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "tagbox",
          name: "anxietySubCategories",
          visibleIf:
            "{mentalHealthSubCategories} contains 'Anxiety & Phobias' and {service} = 'Psychology'",
          title:
            "Please select the types of Anxiety and Phobia issues you are experiencing?",
          requiredIf:
            "{mentalHealthSubCategories} contains 'Anxiety & Phobias' and {service} = 'Psychology'",
          choices: [
            "General Anxiety",
            "Social Anxiety",
            "Specific Phobias",
            "Agoraphobia (difficulty being outside in public spaces)",
            "Attachment anxiety (children)",
          ],
          maxSelectedChoices: 3,
        },
        {
          type: "boolean",
          name: "seekingSpecialistClinician",
          visibleIf: "{service} = 'Psychology'",
          requiredIf: "{service} = 'Psychology'",
          title:
            "Are you seeking a practitioner with specialized training in any particular type of therapy? ",
        },
        {
          type: "checkbox",
          name: "whichSpeciality",
          visibleIf: "{seekingSpecialistClinician} = true ",
          title: "Which speciality?",
          requiredIf: "{seekingSpecialistClinician} = true",
          choices: [
            "Cognitive Behaviour Therapy (CBT)",
            "Schema Therapy",
            "Acceptance and Commitment Therapy (ACT)",
            "Interpersonal Psychotherapy (IPT)",
            "Eye Movement Desensitisation and Reprocessing Therapy (EMDR)",
            "ABA Therapy",
            "Cognitive Processing Therapy",
            "Solution Focused Therapy",
            "Coaching",
            "Couples Therapy",
            "Family Therapy",
            "Psychodynamic Therapy ",
            "Dialectical Behaviour Therapy",
            "Play Therapy",
            "Clinical Hypnotherapy",
            "Hypnotherapy, Play Therapy",
            "Neuropsychology",
            "Behavioural Support",
            "Cognitive-Behavioural Intergrated Therapy (C-BIT)",
            "Neuroaffirming",
            "Other",
          ],
        },
        {
          type: "boolean",
          name: "currentPsych",
          visibleIf: "{service} = 'Psychology'",
          title:
            "Are you currently seeing any other professional i.e. psychiatrist/paediatrician to support your mental health?",
        },
        {
          type: "comment",
          name: "moreInfoOnOtherHealth",
          visibleIf: "{currentPsych} = true",
          title:
            "Please list names and occupation of other people involved in your care.",
          requiredIf: "{currentPsych} = true",
        },
        {
          type: "boolean",
          name: "timePreference",
          title: "Do you have preferred days and times for your appointment?",
          isRequired: true,
        },
        {
          type: "boolean",
          name: "telehealthPreference",
          title: "Would you be willing to see a practitioner via Telehealth?",
          isRequired: true,
        },
        {
          type: "paneldynamic",
          name: "availPanel",
          title:
            "There may be multiple locations available that you can choose from, please select all locations you are willing to travel to.",
          isRequired: true,
          templateElements: [
            {
              type: "dropdown",
              name: "location",
              title: "Location",
              isRequired: true,
              choices: [
                {
                  value: "Location A",
                  text: "Bacchus Marsh",
                },
                {
                  value: "Location B",
                  text: "Ballarat",
                },
                {
                  value: "Location C",
                  text: "Geelong",
                },
                "Telehealth",
              ],
            },
            {
              type: "checkbox",
              name: "day",
              title: "Do you have a preferred day for your appointment? ",
              visibleIf: "{timePreference} = true",
              requiredIf: "{timePreference} = true",
              isRequired: true,
              choices: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday ",
                "Friday",
                "Saturday",
                "Sunday",
              ],
            },
            {
              type: "panel",
              name: "question12",
              visibleIf: "{timePreference} = true",
              requiredIf: "{timePreference} = true",
              elements: [
                {
                  type: "tagbox",
                  name: "timeOfDay",
                  title: "Times of day",
                  isRequired: true,
                  choices: [
                    {
                      value: "Morning",
                      text: "Morning (8-12)",
                    },
                    {
                      value: "Early Afternoon",
                      text: "Early Afternoon (12-3)",
                    },
                    {
                      value: "Late Afternoon",
                      text: "Late Afternoon (3-6)",
                    },
                    {
                      value: "Evening",
                      text: "Evening (6-8)",
                    },
                  ],
                },
              ],
              isRequired: true,
            },
          ],
        },
        {
          type: "boolean",
          name: "legalMatters",
          title: "Are you currently involved in any legal matters or cases?",
          isRequired: true,
        },
        {
          type: "comment",
          name: "moreDetailsLegal",
          visibleIf: "{legalMatters} = true",
          title: "Can you provide more details on the legal matters or cases?",
          requiredIf: "{legalMatters} = true",
        },
        {
          type: "html",
          name: "currentLegalDisclaimer",
          html: "Please note that this practice may not routinely conduct court reports or assessments. If you require these services, please contact us to discuss your needs further. However, please be aware that we may need to refer you to a specialized service that can better meet your needs.",
        },
        {
          type: "comment",
          name: "additionalInfo",
          title:
            "Please add any additional information that may help us in finding the right healthcare practitioner for you.",
        },
        {
          type: "file",
          name: "uploadedDocs",
          title:
            "Please upload your referral. If you have other documents you’d like us to see prior to making an appointment, it is essential you have the appropriate permissions to share these documents. Maximum file size is 20MB. File types accepcted are .jpg, .pdf, .doc, .docx, .png",
          allowMultiple: true,
          allowImagesPreview: false,
          acceptedTypes: ".jpg,.pdf,.doc,.docx,.png",
          storeDataAsText: false,
          waitForUpload: true,
          maxSize: 2000000,
        },
        {
          type: "radiogroup",
          name: "referralSource",
          title: "How did you hear about our service?",
          choices: ["Internet Search", "Social Media", "Word of Mouth", "Professional Referral (GP, Psychiatrist, Paediatrician)"],
          showOtherItem: true,
        },
        {
          type: "comment",
          name: "professionalReferralSource",
          visibleIf: "{referralSource} = 'Professional Referral (GP, Psychiatrist, Paediatrician)'",
          title: "Please provide the name of the professional who referred you.",
        },
        {
          type: "html",
          name: "currentLegalDisclaimer",
          html: 'By clicking "Complete" you acknowledge and agree to our privacy policy and terms of service. Please be aware that this form is general in nature, and not all services may be available at this practice or address specific presenting issues.',
        },
      ],
    },
  ],
};

export default clientFormJson;
